<template>
  <div class="coin--summary-container" v-if="user && !loading">
    <v-card class="coin--summary">
      <div class="top--part">
        <div class="d-flex align-center">
          <div>
            <img class="coin--icon" src="@/assets/img/icons/coin.png" alt="Coin" />
          </div>
          <div>
            <div class="total--coin">{{ totalCoins ? totalCoins : 0 }}</div>
            <div class="coin--name">{{ $t('general.totalCoin') }}</div>
          </div>
        </div>
        <div class="listing--availability">
          <div class="total--coin">
            {{ `${currentAmount ? currentAmount : 0}/${quota ? quota : 0}` }}
          </div>
          <div class="coin--name">{{ $t('general.activeListingSlot') }}</div>
        </div>
      </div>
      <router-link to="/coin" class="btn btn-primary coin--cta">{{
        $t('general.buyCoin')
      }}</router-link>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  props: [],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      totalCoins: (state) => state.coins.totalCoins,
      currentAmount: (state) => state.listingAvailability.currentAmount,
      quota: (state) => state.listingAvailability.quota,
    }),
  },
  async mounted() {
    try {
      this.loading = true;
      await Promise.all([
        this.$store.dispatch('coins/getTotalCoins'),
        this.$store.dispatch('listingAvailability/getListingAvailability'),
      ]);
    } finally {
      this.loading = false;
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.coin--summary-container {
  position: relative;
  @media #{$phones} {
    width: 100% !important;
  }
}
.coin--summary {
  padding: 24px 32px;
  border-radius: 30px !important;
  background-color: $color-beliruma-1-light;
  @media #{$phones} {
    padding: 20px 28px;
  }
  .top--part {
    display: flex;
    align-items: center;
    padding: 0 12px;
    @media #{$phones} {
      justify-content: center;
      /*flex-wrap: wrap;*/
    }
  }
  .total--coin {
    font-family: Poppins-Bold;
    font-size: $font-xdl;
    color: $color-text-gray-3;
    @media #{$phones} {
      font-size: $font-xl;
    }
  }
  .coin--name {
    font-size: $font-md;
    color: $color-text-gray-3;
    @media #{$phones} {
      font-size: $font-sm;
    }
  }
  .coin--icon {
    width: 80px;
    object-fit: contain;
    margin-right: 24px;
    @media #{$phones} {
      width: 60px;
      margin-right: 12px;
    }
  }
  .coin--cta {
    margin-top: 24px;
    border-radius: 20px !important;
  }
  .listing--availability {
    margin-left: 16px;
    padding-left: 16px;
    border-left: solid 4px $color-text-gray-3;
  }
}
</style>
